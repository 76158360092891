<template>
  <div>
    <NavTitle class="mb16" title="整改列表" :showBack="false">
      <!-- <template #right>
        <div class="input-row">
          <el-input class="input" placeholder="请输入用户ID/商户ID或投诉关键词" clearable v-model="seachWord" @clear="handleSearch"></el-input>
          <el-button slot="append" type="primary" icon="el-icon-search"  @click="handleSearch"></el-button>
        </div>
      </template> -->
    </NavTitle>
    <div class="ml20 mr20">
      <el-menu  default-active="1" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item index="1">待处理</el-menu-item>
        <el-menu-item index="2">处理完成</el-menu-item>
      </el-menu>
    </div>

    <el-row :gutter="20" class="pl20 pr20" v-if="rectifyList && rectifyList.length > 0">
      <el-col :span="12" class="pointer mt16" v-for="(item, index) in rectifyList" :key="index">
        <div class="rectify" @click="toDetail(item)">
          <div class="flex between-center">
            <span class="name">{{item.entName}}</span>
            <div class="flex items-center">
              <div class="desc-status">{{item.statusDesc}}</div>
              <span class="time">{{item.dt}}</span>
            </div>
          </div>
          <div class="flex between-center mt16" v-if="item.merchantContact">
            <span>
              商家联系方式 <span>({{item.merchantContact.name}})</span>：
            </span>
            <span>{{item.merchantContact.content}}</span>
          </div>
          <div class="reason mt16">整改原因：{{item.reason}}</div>
        </div>
      </el-col>
    </el-row>
    <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
    <!-- 分页 -->
    <div class="flex justify-center mt24" v-if="rectifyList.length > 0">
      <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import NavTitle from '@/components/NavTitle'
import { auditRectifyListApi } from '@/api/admin.js'
export default {
  name: 'RectifyList',
  components: {
    NavTitle,
    Pagination
  },
  data() {
    return {
      curPage: 0,
      listType: 1, //1-待处理 2-处理完成
      seachWord: '',
      rectifyList: []
    }
  },
  created() {
    this.getRectifyList()
  },
  methods: {
    getRectifyList() {
      console.log('listType', this.listType)
      auditRectifyListApi({
        'listType': this.listType,
        'pageSize': 20,
        'startPage': this.curPage
      }).then(res => {
        console.log('整改列表', res)
        const list = res.data.auditRectifyLists || []
        if(list.length != 0 || this.curPage == 0) {
          this.rectifyList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    menuSelect(index) {
      this.listType = index
      this.curPage = 0
      this.getRectifyList()
    },
    nextPage() {
      this.curPage++
      this.getRectifyList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getRectifyList()
    },
    handleSearch() {
      this.curPage = 0
      this.getRectifyList()
    },
    toDetail(item) {
      this.$router.push({
        path: '/rectify/detail',
        query: { 
          rid: item.rid,
          type: this.listType
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
.rectify {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  font-size: 12px;
  color: #505E5C;
  .name {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: #001A18;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .reason {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    color: #001A18;
  }
  .desc-status {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    background-color: var(--theme-color-red);
    margin-right: 8px;
  }
}
</style>